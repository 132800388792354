export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Crew',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Supir',
        route: 'supir',
      },
      {
        title: 'Kernet',
        route: 'kernet',
      },
      {
        title: 'Karyawan',
        route: 'karyawan',
      },
    ],
  },
  {
    title: 'Bis',
    route: 'bus',
    icon: 'BoldIcon',
  },
  {
    title: 'Booking',
    route: 'booking',
    icon: 'CalendarIcon',
  },
  {
    title: 'Keuangan',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Keuangan',
        route: 'keuangan',
      },
      {
        title: 'Kas Bis',
        route: 'kas',
      },
      {
        title: 'Hutang Kru',
        route: 'hutang-kru',
      },
      {
        title: 'Pembayaran Supir',
        route: 'pembayaran-supir',
      },
      {
        title: 'Gaji Karyawan',
        route: 'gaji-karyawan',
      },
    ],
  },
]
